import {
  CHECK_LIKE,
  GET_CATEGORY_PODCASTS_FAIL,
  GET_CATEGORY_PODCASTS_REQUEST,
  GET_CATEGORY_PODCASTS_SUCCESS,
  GET_LIKED_PODCASTS_FAIL,
  GET_LIKED_PODCASTS_REQUEST,
  GET_LIKED_PODCASTS_SUCCESS,
  GET_MOST_LIKED_PODCASTS_FAIL,
  GET_MOST_LIKED_PODCASTS_REQUEST,
  GET_MOST_LIKED_PODCASTS_SUCCESS,
  GET_MOST_VIEWED_PODCASTS_FAIL,
  GET_MOST_VIEWED_PODCASTS_REQUEST,
  GET_MOST_VIEWED_PODCASTS_SUCCESS,
  INCREMENT_VIEWS_COUNT,
} from "../types";

import axios from "axios";
import URL, { portalId } from "../api";
import {
  GET_LATEST_PODCASTS_FAIL,
  GET_LATEST_PODCASTS_REQUEST,
  GET_LATEST_PODCASTS_SUCCESS,
  GET_PODCASTS_FAIL,
  GET_PODCASTS_REQUEST,
  GET_PODCASTS_SUCCESS,
  PODCAST_DETAILS_FAIL,
  PODCAST_DETAILS_REQUEST,
  PODCAST_DETAILS_SUCCESS,
  UPDATE_LIKES,
} from "../types";

export const getPodcasts =
  (keyword = "", page = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_PODCASTS_REQUEST });

      const { data } = await axios.get(
        URL + `/podcast/${portalId}/search?s=${keyword}&page=${page}`
      );

      dispatch({ type: GET_PODCASTS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: GET_PODCASTS_FAIL, payload: error.message });
      console.log(error);
    }
  };

export const getCategoryPodcasts =
  (categoryId = "", page = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_CATEGORY_PODCASTS_REQUEST });

      const { data } = await axios.get(
        URL + `/category/${categoryId}/podcasts?page=${page}`
      );

      dispatch({
        type: GET_CATEGORY_PODCASTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_CATEGORY_PODCASTS_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data.errors
            : error.message,
      });
    }
  };

export const getLikedPodcasts =
  (page = 1) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: GET_LIKED_PODCASTS_REQUEST });
      const {
        loginUser: { user },
      } = getState();

      const config = {
        headers: {
          authorization: "Bearer " + user?.token,
        },
      };
      const { data } = await axios.get(
        URL + `/podcast/user/likes?page=${page}`,
        config
      );

      dispatch({
        type: GET_LIKED_PODCASTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_LIKED_PODCASTS_FAIL,
        payload:
          error.response && error.response.data
            ? error.response.data.errors
            : error.message,
      });
    }
  };

export const checkLike = (id) => async (dispatch, getState) => {
  try {
    const {
      loginUser: { user },
    } = getState();

    const config = {
      headers: {
        authorization: "Bearer " + user?.token,
      },
    };

    const { data } = await axios.post(
      URL + "/podcast/like",
      { podcast_id: id },
      config
    );

    dispatch({ type: CHECK_LIKE, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const getPodcastDetails = (podcastId) => async (dispatch, getState) => {
  try {
    dispatch({ type: PODCAST_DETAILS_REQUEST });

    const {
      loginUser: { user },
    } = getState();

    const config = {
      headers: {
        authorization: "Bearer " + user?.token,
      },
    };
    const { data } = await axios.get(URL + `/podcast/${podcastId}`, config);

    dispatch({ type: PODCAST_DETAILS_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: PODCAST_DETAILS_FAIL, payload: error.message });
    console.log(error);
  }
};
export const getLatestPodcasts = (categoryId) => async (dispatch) => {
  try {
    dispatch({ type: GET_LATEST_PODCASTS_REQUEST });
    const { data } = await axios.get(
      URL + `/podcast/${portalId}/top?criteria=latest&category_id=${categoryId}`
    );
    dispatch({ type: GET_LATEST_PODCASTS_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: GET_LATEST_PODCASTS_FAIL, payload: error.message });
    console.log(error);
  }
};

export const getMostLikedPodcasts = () => async (dispatch) => {
  try {
    dispatch({ type: GET_MOST_LIKED_PODCASTS_REQUEST });
    const { data } = await axios.get(URL + `/podcast/top?criteria=liked`);
    dispatch({ type: GET_MOST_LIKED_PODCASTS_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: GET_MOST_LIKED_PODCASTS_FAIL, payload: error.message });
    console.log(error);
  }
};

export const getMostViewedPodcasts = () => async (dispatch) => {
  try {
    dispatch({ type: GET_MOST_VIEWED_PODCASTS_REQUEST });
    const { data } = await axios.get(URL + `/podcast/top?criteria=viewed`);
    dispatch({ type: GET_MOST_VIEWED_PODCASTS_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: GET_MOST_VIEWED_PODCASTS_FAIL, payload: error.message });
    console.log(error);
  }
};

export const LikePodcast = (id) => async (dispatch, getState) => {
  // console.log(id);
  // console.log(podcasts, "before");
  // podcasts.forEach((x) => {
  //   if (x.id === id) {
  //     x.liked = true;
  //     x.likeCount = x.likeCount + 1;
  //   }
  // });
  // console.log(podcasts, "after");
  // dispatch({ type: LIKE_PODCAST });
  try {
    const {
      loginUser: { user },
      podcastDetails: { podcast },
    } = getState();

    const config = {
      headers: {
        authorization: "Bearer " + user?.token,
      },
    };
    const { data } = await axios.post(
      URL + `/podcast/like`,
      { podcast_id: id },
      config
    );

    console.log({ data });
    dispatch({ type: UPDATE_LIKES, payload: { ...data, liked: true } });
    // dispatch({
    //   type: PODCAST_DETAILS_SUCCESS,
    //   payload: { ...podcast, liked: true, likes: data.likes },
    // });
  } catch (error) {
    console.error(error);
  }
};
export const UNLikePodcast = (id) => async (dispatch, getState) => {
  // podcasts.forEach((x) => {
  //   if (x.id === id) {
  //     x.liked = false;
  //     x.likeCount = x.likeCount - 1;
  //   }
  // });
  // dispatch({ type: UNLIKE_PODCAST });

  try {
    const {
      loginUser: { user },
      podcastDetails: { podcast },
    } = getState();

    const config = {
      headers: {
        authorization: "Bearer " + user?.token,
      },
    };
    const { data } = await axios.post(
      URL + `/podcast/dislike`,
      { podcast_id: id },
      config
    );

    dispatch({ type: UPDATE_LIKES, payload: { ...data, liked: false } });
    // dispatch({
    //   type: PODCAST_DETAILS_SUCCESS,
    //   payload: { ...podcast, liked: false, likes: data.likes },
    // });
  } catch (error) {
    console.error(error);
  }
};

export const incPodastViews = (id) => async (dispatch, getState) => {
  try {
    const {
      loginUser: { user },
    } = getState();

    const config = {
      headers: {
        authorization: "Bearer " + user?.token,
      },
    };
    const { data } = await axios.post(
      `${URL}/podcast/${id}/increment_views`,
      {
        podcast_id: id,
      },
      config
    );
    dispatch({ type: INCREMENT_VIEWS_COUNT, payload: data });
  } catch (error) {
    console.error(error);
  }
};
