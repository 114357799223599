import {
  AUTHENTICATE_USER,
  AUTHENTICATE_USER_FAIL,
  AUTHENTICATE_USER_REQUEST,
  AUTHENTICATE_USER_SUCCESS,
  CHECK_USER_AUTH,
  CLOSE_AUTH_MODAL,
  LOGOUT_USER,
  OPEN_AUTH_MODAL,
  SUBSCRIBE_USER_FAIL,
  SUBSCRIBE_USER_REQUEST,
  SUBSCRIBE_USER_SUCCESS,
} from "../types";

export const authReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECK_USER_AUTH:
      return { ...state, isAuthenticated: action.payload };
    case AUTHENTICATE_USER:
      return { isAuthenticated: true };

    default:
      return state;
  }
};

export const subscribeUserReducer = (
  state = { user: null, loading: false },
  action
) => {
  switch (action.type) {
    case SUBSCRIBE_USER_REQUEST:
      return { ...state, loading: true };
    case SUBSCRIBE_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload };
    case SUBSCRIBE_USER_FAIL:
      return { loading: false, error: action.payload };
    case LOGOUT_USER:
      return { ...state, user: null };
    default:
      return state;
  }
};
export const loginUserReducer = (
  state = { user: null, loading: false },
  action
) => {
  switch (action.type) {
    case AUTHENTICATE_USER_REQUEST:
      return { ...state, loading: true };
    case AUTHENTICATE_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload };
    case AUTHENTICATE_USER_FAIL:
      return { loading: false, error: action.payload };
    case LOGOUT_USER:
      return { ...state, user: null };
    default:
      return state;
  }
};
export const authModalReducer = (state = { open: false }, action) => {
  switch (action.type) {
    case OPEN_AUTH_MODAL:
      return { ...state, open: true };
    case CLOSE_AUTH_MODAL:
      return { ...state, open: false };

    default:
      return state;
  }
};
