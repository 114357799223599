//Podcast type
export const LIKE_PODCAST = "LIKE_PODCAST";
export const UNLIKE_PODCAST = "UNLIKE_PODCAST";

export const CHECK_LIKE = "CHECK_LIKE";

export const ADD_TO_PLAYLIST = "ADD_TO_PLAYLIST";
export const GET_PLAYLIST = "GET_PLAYLIST";
export const RESET_ADDED = "RESET_ADDED";

export const UPDATE_CURRENT_PLAY = "UPDATE_CURRENT_PLAY";
export const RESET_CURRENT_PLAY = "RESET_CURRENT_PLAY";

export const ADD_TO_RECENT_PLAYS = "ADD_TO_RECENT_PLAYS";
export const GET_RECENT_PLAYS = "GET_RECENT_PLAYS";

//auth

export const CHECK_USER_AUTH = "CHECK_USER_AUTH";
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";

export const OPEN_AUTH_MODAL = "OPEN_AUTH_MODAL";
export const CLOSE_AUTH_MODAL = "CLOSE_AUTH_MODAL";

export const SUBSCRIBE_USER_REQUEST = "SUBSCRIBE_USER_REQUEST";
export const SUBSCRIBE_USER_SUCCESS = "SUBSCRIBE_USER_SUCCESS";
export const SUBSCRIBE_USER_FAIL = "SUBSCRIBE_USER_FAIL";

export const AUTHENTICATE_USER_REQUEST = "AUTHENTICATE_USER_REQUEST";
export const AUTHENTICATE_USER_SUCCESS = "AUTHENTICATE_USER_SUCCESS";
export const AUTHENTICATE_USER_FAIL = "AUTHENTICATE_USER_FAIL";
export const LOGOUT_USER = "LOGOUT_USER";

export const GET_PODCASTS_REQUEST = "GET_PODCASTS_REQUEST";
export const GET_PODCASTS_SUCCESS = "GET_PODCASTS_SUCCESS";
export const GET_PODCASTS_FAIL = "GET_PODCASTS_REQUEST";

export const GET_LIKED_PODCASTS_REQUEST = "GET_LIKED_PODCASTS_REQUEST";
export const GET_LIKED_PODCASTS_SUCCESS = "GET_LIKED_PODCASTS_SUCCESS";
export const GET_LIKED_PODCASTS_FAIL = "GET_LIKED_PODCASTS_FAIL";

export const GET_CATEGORY_PODCASTS_REQUEST = "GET_CATEGORY_PODCASTS_REQUEST";
export const GET_CATEGORY_PODCASTS_SUCCESS = "GET_CATEGORY_PODCASTS_SUCCESS";
export const GET_CATEGORY_PODCASTS_FAIL = "GET_CATEGORY_PODCASTS_FAIL";

export const PODCAST_DETAILS_REQUEST = "PODCAST_DETAILS_REQUEST";
export const PODCAST_DETAILS_SUCCESS = "PODCAST_DETAILS_SUCCESS";
export const PODCAST_DETAILS_FAIL = "PODCAST_DETAILS_FAIL";
export const GET_LATEST_PODCASTS_REQUEST = "GET_LATEST_PODCASTS_REQUEST";
export const GET_LATEST_PODCASTS_SUCCESS = "GET_LATEST_PODCASTS_SUCCESS";
export const GET_LATEST_PODCASTS_FAIL = "GET_LATEST_PODCASTS_FAIL";

export const SET_CURRENT_PODCAST = "SET_CURRENT_PODCAST";
export const OPEN_AUDIO_PLAYER = "OPEN_AUDIO_PLAYER";
export const CLOSE_AUDIO_PLAYER = "CLOSE_AUDIO_PLAYER";

export const GET_MOST_LIKED_PODCASTS_REQUEST =
  "GET_MOST_LIKED_PODCASTS_REQUEST";
export const GET_MOST_LIKED_PODCASTS_SUCCESS =
  "GET_MOST_LIKED_PODCASTS_SUCCESS";
export const GET_MOST_LIKED_PODCASTS_FAIL = "GET_MOST_LIKED_PODCASTS_REQUEST";

export const GET_MOST_VIEWED_PODCASTS_REQUEST =
  "GET_MOST_VIEWED_PODCASTS_REQUEST";
export const GET_MOST_VIEWED_PODCASTS_SUCCESS =
  "GET_MOST_VIEWED_PODCASTS_SUCCESS";
export const GET_MOST_VIEWED_PODCASTS_FAIL = "GET_MOST_VIEWED_PODCASTS_REQUEST";

export const UPDATE_LIKES = "UPDATE_LIKES";

export const CREATE_REVIEW_REQUEST = "CREATE_REVIEW_REQUEST";
export const CREATE_REVIEW_SUCCESS = "CREATE_REVIEW_SUCCESS";
export const CREATE_REVIEW_FAIL = "CREATE_REVIEW_FAIL";

//views
export const INCREMENT_VIEWS_COUNT = "INCREMENT_VIEWS_COUNT";

// categories actions

export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_REQUEST";

export const GET_CATEGORY_REQUEST = "GET_CATEGORY_REQUEST";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAIL = "GET_CATEGORY_REQUEST";

//subscribe actions

export const SUBSCRIBE_INITIATE_REQUEST = "SUBSCRIBE_INITIATE_REQUEST";
export const SUBSCRIBE_INITIATE_SUCCESS = "SUBSCRIBE_INITIATE_SUCCESS";
export const SUBSCRIBE_INITIATE_FAIL = "SUBSCRIBE_INITIATE_FAIL";

export const SUBSCRIBE_COMPLETE_REQUEST = "SUBSCRIBE_COMPLETE_REQUEST";
export const SUBSCRIBE_COMPLETE_SUCCESS = "SUBSCRIBE_COMPLETE_SUCCESS";
export const SUBSCRIBE_COMPLETE_FAIL = "SUBSCRIBE_COMPLETE_FAIL";

export const SUBSCRIBE_STATUS_REQUEST = "SUBSCRIBE_STATUS_REQUEST";
export const SUBSCRIBE_STATUS_SUCCESS = "SUBSCRIBE_STATUS_SUCCESS";
export const SUBSCRIBE_STATUS_FAIL = "SUBSCRIBE_STATUS_FAIL";

//Settings actions

export const OPEN_VERIFY_MODAL = "OPEN_VERIFY_MODAL";
export const CLOSE_VERIFY_MODAL = "CLOSE_VERIFY_MODAL";
export const OPEN_SHARE_MODAL = "OPEN_SHARE_MODAL";
export const CLOSE_SHARE_MODAL = "CLOSE_VERIFY_MODAL";
export const OPEN_SUBSCRIBTION_MODAL = "OPEN_SUBSCRIBTION_MODAL";
export const CLOSE_SUBSCRIBTION_MODAL = "CLOSE_SUBSCRIBTION_MODAL";
export const OPEN_WARNING = "OPEN_WARNING";
export const CLOSE_WARNING = "CLOSE_WARNING";
