import {  Pagination, Slide, ThemeProvider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import AudioPLayer from "../components/AudioPLayer";
import AuthModal from "../components/AuthModal";

import Header from "../components/Header";
import Hero from "../components/Hero";
import Loader from "../components/Loader";
import Podcast from "../components/Podcast";
import theme from "../config/theme";
import { getCategoryPodcasts } from "../redux/actions/podcastActions";
import { OPEN_AUTH_MODAL, SET_CURRENT_PODCAST } from "../redux/types";

const CategoryPage = () => {
  const { category: categoryId } = useParams();

  const { podcasts, loading, pages } = useSelector(
    (state) => state.categoryPodcasts
  );

  const [page, setPage] = useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const { categories } = useSelector((state) => state.categoriesList);

  const { user } = useSelector((state) => state.loginUser);

  const category = categories?.find((x) => x.id === categoryId);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategoryPodcasts(categoryId, page));
  }, [categoryId, dispatch, page]);

  const handlePlay = (podcast) => {
    if (!user) return dispatch({ type: OPEN_AUTH_MODAL });
    dispatch({ type: SET_CURRENT_PODCAST, payload: podcast });
  };
  return (
    <Slide in={true} timeout={1000} unmountOnExit>
      <div className="bg-slate-50">
        <Header />
        <Hero />

        <h2 className="p-4 border-b border-black mx-12 my-8 mb-24  text-4xl ">
          {category?.name}
        </h2>
        <main className="min-h-screen mb-40">
          {loading ? (
            <Loader />
          ) : (
            <div className="grid grid-cols-12 justify-items-center gap-y-9 my-6">
              {podcasts?.map((x) => (
                <Podcast
                  podcast={x}
                  key={x.id}
                  handlePlay={() => handlePlay(x)}
                />
              ))}
            </div>
          )}

          <ThemeProvider theme={theme}>
            <div className="col-span-12 grid place-items-center my-6">
              {pages > 1 && (
                <Pagination
                  color="primary"
                  count={pages}
                  page={page}
                  onChange={handlePageChange}
                  shape="rounded"
                  variant="outlined"
                />
              )}
            </div>
          </ThemeProvider>
        </main>
        <div className="w-full h-1" />
        {/* <AudioPLayer /> */}
        <AuthModal />
      </div>
    </Slide>
  );
};

export default CategoryPage;
