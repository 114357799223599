import { Grow } from "@mui/material";
import React from "react";

import AuthModal from "../components/AuthModal";
import Content from "../components/Content";
import Features from "../components/Features";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Player from "../components/Player";

const Home = () => {
  return (
    <Grow in={true} timeout={2500}>
      <div className="bg-slate-50 ">
        <Header />

        <Hero />
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#FFCC00"
            fillOpacity="1"
            d="M0,96L60,122.7C120,149,240,203,360,208C480,213,600,171,720,138.7C840,107,960,85,1080,69.3C1200,53,1320,43,1380,37.3L1440,32L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
        </svg>

        {/* <Features /> */}

        <Content />

        <Footer />
        <div className="w-full h-12" />

        <Player />
        <AuthModal />
      </div>
    </Grow>
  );
};

export default Home;
