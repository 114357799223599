import React from "react";
import Header from "../components/Header";

import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPodcastDetails,
  LikePodcast,
  UNLikePodcast,
} from "../redux/actions/podcastActions";
import { useParams } from "react-router-dom";

import backgroundImage from "../assets/images/background.png";
import URL from "../redux/api";
import { IconButton } from "@mui/material";
import {
  FavoriteBorderOutlined,
  FavoriteSharp,
  Share,
  Visibility,
} from "@mui/icons-material";
import ShareModal from "../components/ShareModal";
import { useState } from "react";

const PodcastPage = () => {
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  const { podcast } = useSelector((state) => state.podcastDetails);
  useEffect(() => {
    dispatch(getPodcastDetails(id));
  }, [id]);
  return (
    <div
      className="min-h-screen "
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100%",
        backgroundPosition: "center center",
        backgroundAttachment: "fixed",
      }}>
      <Header />
      {/* <img
        src="/mSound_Templet.jpg"
        className="fixed top-0 left-0 right-0 bottom-0   "
        alt=""
      /> */}

      <div className="flex justify-center items-center flex-col min-h-screen">
        <div className="md:flex justify-center">
          <div className="flex-[.5] ">
            <img
              dir="rtl"
              src={`${podcast?.image}`}
              className="w-[100%] rounded-sm h-[25rem]"
            />
          </div>
          <div className="flex-[.5]  md:mr-10 mt-4 flex flex-col items-start min-h-full ">
            <h3
              style={{ textShadow: "0px 2px 0px rgba(0, 0, 0, 1)" }}
              className="text-xl md:text-5xl text-white">
              {podcast?.name}
            </h3>
            <p
              style={{ textShadow: "0px 2px 0px rgba(0, 0, 0, 1)" }}
              className="w-[65%] mt-2 md:w-full md:text-2xl text-white text-justify">
              {podcast?.description}
            </p>
            {/*  */}

            <div className="h-full flex   items-end">
              <div className="flex items-center">
                <IconButton className="child:!text-[1rem] md:child:!text-[1.4rem]">
                  {podcast?.liked ? (
                    <FavoriteSharp
                      onClick={() => dispatch(UNLikePodcast(podcast?.id))}
                      className="!text-red-600"
                    />
                  ) : (
                    <FavoriteBorderOutlined
                      className="text-white"
                      onClick={() => dispatch(LikePodcast(podcast?.id))}
                    />
                  )}{" "}
                </IconButton>
                <span className="text-xs mt-1 text-white">
                  {podcast?.likes}
                </span>
              </div>

              <div className="flex items-center mr-3">
                <IconButton className="child:!text-[1rem] !text-white md:child:!text-[1.4rem]">
                  <Visibility />
                </IconButton>
                <span className="text-xs mt-1 text-white">
                  {podcast?.views}
                </span>
              </div>
              <IconButton
                onClick={() => setOpen(true)}
                className="child:!text-[1rem] !text-white md:child:!text-[1.4rem] !mr-3">
                <Share />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      <div dir="ltr">
        <AudioPlayer
          className="fixed bottom-0 !bg-transparent "
          src={`${URL}/podcast/${podcast?.id}/sound?Authorization=10|VJfEmb76wkHuzM0DCvDn433u2khJ5KkkX7ojwIrD`}
        />
      </div>

      <ShareModal podcast={podcast} open={open} setOpen={setOpen} />
      {/* <div className="min-h-full"></div> */}
      {/* <div className="bg-black w-full h-[50vh]"></div> */}
    </div>
  );
};

export default PodcastPage;
