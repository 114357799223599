import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
} from "@mui/material";
import React from "react";

import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const ShareModal = ({ podcast, open, setOpen }) => {
  // const { open } = useSelector((state) => state.AuthModal);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle>شارك عبر المنصات الاجتماعية!</DialogTitle>

      <DialogActions className="!flex !justify-between  md:w-[20rem] !p-2 items-center">
        <FacebookShareButton
          quote={`<strong>${podcast?.name}</strong> - ${podcast?.description}`}
          url={window.location.href}
          hashtag="#podcasts">
          <FacebookIcon size={50} borderRadius={10} />
        </FacebookShareButton>
        <TwitterShareButton url={window.location.href} title={podcast?.name}>
          <TwitterIcon size={50} borderRadius={10} />
        </TwitterShareButton>
        <WhatsappShareButton url={window.location.href} title={podcast?.name}>
          <WhatsappIcon size={50} borderRadius={10} />
        </WhatsappShareButton>
        <TelegramShareButton url={window.location.href} title={podcast?.name}>
          <TelegramIcon size={50} borderRadius={10} />
        </TelegramShareButton>
      </DialogActions>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default ShareModal;
