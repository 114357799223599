import { SentimentDissatisfiedOutlined } from "@mui/icons-material";
import { Drawer, Paper } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useSelector } from "react-redux";
import URL from "../redux/api";

const Player = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.loginUser);
  const { open, podcast } = useSelector((state) => state.player);
  const [error, setError] = useState(false);
  console.log({ podcast });
  useEffect(() => {
    return () => {
      setError(false);
    };
  }, [podcast?.i]);
  return (
    <Drawer open={open} anchor="bottom" dir="ltr" variant="persistent">
      <Paper className="pb-2">
        {error ? (
          <p className="text-center p-4">
            <SentimentDissatisfiedOutlined />
            يتعذر تشغيل هذا البودكاست الان يرجى المحاولة لاحقا
          </p>
        ) : (
          <>
            <div className={`pt-2 text-center ${loading && "animate-pulse"}`}>
              <h2>{podcast?.description}</h2>
            </div>
            {!user && (
              <AudioPlayer
                className="!bg-white !tex-black child:!text-black !shadow-none"
                // customIcons={{
                //   play: (
                //     <IconButton>
                //       <PlayCircleOutline className=" !text-[40px] !w-[35px] !h-[35px] !text-white" />
                //     </IconButton>
                //   ),
                //   pause: (
                //     <IconButton className="relative">
                //       <Pause className=" !text-[30px] !w-[35px] !h-[35px] !text-white" />
                //       {loading && (
                //         <CircularProgress className="absolute  !text-white" />
                //       )}
                //     </IconButton>
                //   ),
                // }}

                autoPlay
                src={`${URL}/podcast/${podcast?.id}/sound?Authorization=8|2gLxDKDB3LDYOLY2WQOy55CgUgnEcu83QbiUXbkl`}
              />
            )}
          </>
        )}
      </Paper>
    </Drawer>
  );
};

export default Player;
