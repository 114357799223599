import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../redux/actions/authActions";

const Footer = () => {
  const { user } = useSelector((state) => state.loginUser);
  const dispatch = useDispatch();
  return (
    <footer
      style={{ backgroundImage: "url('/preview.png')" }}
      className="mx-auto !bg-[length:15%_40%] sm:!bg-[length:15%_60%] call-to-action my-24 p-6 flex items-center justify-between  !bg-[#FFCC00] w-[60%] rounded-lg h-[12rem]  ">
      <div>
        <h2 className="lg:text-3xl md:text-2xl text-xl font-bold">انضم الآن</h2>
        <p className="md:text-xl">الى البودكاست الرائع من mtn</p>

        {user ? (
          <button
            className="bg-black rounded p-2 mt-4 text-white"
            onClick={() => dispatch(logout())}>
            {" "}
            إلغاء الاشتراك
          </button>
        ) : (
          <Link
            to="/subscribe"
            className="rounded mt-4 p-2  font-bold text-white bg-black">
            اشترك الآن
          </Link>
        )}
      </div>
      <div />
    </footer>
  );
};

export default Footer;
