import { Search } from "@mui/icons-material";
import { Paper, Popper, Slide } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPodcasts } from "../redux/actions/podcastActions";

const Searchbar = ({ open, anchorEl, slideRef }) => {
  const [keyword, setKeyword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSearch = () => {
    dispatch(getPodcasts(keyword));
    navigate("/explore");
  };
  return (
    <Popper
      //   id={id}

      open={open}
      anchorEl={anchorEl}
      className="!mt-10 !ml-4"
      transition
    >
      {({ TransitionProps }) => (
        <Slide
          unmountOnExit
          {...TransitionProps}
          timeout={350}
          container={slideRef.current}
        >
          <Paper>
            <form className="p-2" onSubmit={handleSearch}>
              <button className="rounded text-black bg-[#FFCC00] p-1 ml-2">
                <Search />{" "}
              </button>
              <input
                type="text"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="بحث..."
                className="border p-1 outline-none"
              />
            </form>
          </Paper>
        </Slide>
      )}
    </Popper>
  );
};

export default Searchbar;
