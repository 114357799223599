import { Close, Login, Menu, Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { logout, subscribe } from "../redux/actions/authActions";
import logo from "../assets/images/MTN_Logo.png";

import { getCategories } from "../redux/actions/categoryActions";
import Searchbar from "./Searchbar";
import Sidebar from "./Sidebar";
import LoadingButton from "./LoadingButton";

const Header = ({ hide }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  const slideRef = useRef(null);

  const handleClick = (e) => {
    setOpenSearch(!openSearch);
    setAnchorEl(e.currentTarget);
  };
  const { user } = useSelector((state) => state.loginUser);

  const { loading } = useSelector((state) => state.subscribeUser);

  const { categories } = useSelector((state) => state.categoriesList);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);
  return (
    <>
      <header className="flex w-full  sticky items-center justify-between z-10 top-0  bg-white py-2 px-[2rem] md:px-[5rem]  xl:px-[12rem] shadow">
        <div className="flex items-center justify-between gap-x-6">
          <IconButton onClick={() => setOpen(!open)} className="md:!hidden ">
            <Menu />
          </IconButton>

          <Link
            className="ml-6 w-24 flex  items-center justify-center gap-x-2"
            to="/">
            <img src="/MSounds.png" alt="logo" className="h-5 block" />
            {/* <h2 className="font-bold ">mSounds</h2> */}
          </Link>
          {!user ? (
            <>
              {/* <Link to="/login" className="hidden md:block">
                تسجيل الدخول
              </Link> */}
              <LoadingButton
                loading={loading}
                // onClick={handleSubscribe}

                className="!w-[7rem] text-xs"
                onClick={() => dispatch(subscribe())}>
                اشترك الآن
              </LoadingButton>
            </>
          ) : (
            <button
              onClick={dispatch(logout())}
              className="hidden md:block text-red-500">
              تسجيل الخروج
            </button>
          )}
        </div>

        <nav className="lg:flex items-center justify-between child:mx-4 lg:child:mx-6 lg:child:text-sm md:child:text-xs  hidden">
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? "active" : "")}>
            الصفحة الرئيسية
          </NavLink>
          {categories?.map((category) => (
            <NavLink
              to={`/categories/${category.id}`}
              state={{ id: category.id }}
              end
              key={category.id}
              className={({ isActive }) =>
                isActive ? "active " : "hover:active"
              }>
              {category.name}
            </NavLink>
          ))}
          <div
            ref={slideRef}
            className="hidden  md:flex items-center space-between">
            {!hide && (
              <IconButton
                onClick={handleClick}
                className="!bg-[#FFCC00] !text-black !rounded shadow-sm !ml-4"
                size="small">
                {openSearch ? <Close /> : <Search />}
              </IconButton>
            )}
          </div>
        </nav>

        <div className="">
          <img src={logo} className="h-10 object-contain rounded block" />
        </div>

        <Searchbar
          open={openSearch}
          anchorEl={anchorEl}
          slideRef={slideRef}
          setAnchorEl={setAnchorEl}
        />
      </header>
      <Sidebar open={open} setOpen={setOpen} />
    </>
  );
};

export default Header;
