import React from "react";
import LoaderUnit from "./LoaderUnit";

const Loader = () => {
  return (
    <main className="min-h-screen mb-40">
      <div className="grid grid-cols-12 justify-items-center gap-y-9 my-6">
        <LoaderUnit />
        <LoaderUnit />
        <LoaderUnit />
        <LoaderUnit />
        <LoaderUnit />
        <LoaderUnit />
      </div>
    </main>
  );
};

export default Loader;
