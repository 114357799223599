import {
  ADD_TO_PLAYLIST,
  ADD_TO_RECENT_PLAYS,
  CHECK_LIKE,
  CLOSE_AUDIO_PLAYER,
  CREATE_REVIEW_SUCCESS,
  GET_CATEGORY_PODCASTS_FAIL,
  GET_CATEGORY_PODCASTS_REQUEST,
  GET_CATEGORY_PODCASTS_SUCCESS,
  GET_LATEST_PODCASTS_FAIL,
  GET_LATEST_PODCASTS_REQUEST,
  GET_LATEST_PODCASTS_SUCCESS,
  GET_LIKED_PODCASTS_FAIL,
  GET_LIKED_PODCASTS_REQUEST,
  GET_LIKED_PODCASTS_SUCCESS,
  GET_MOST_LIKED_PODCASTS_FAIL,
  GET_MOST_LIKED_PODCASTS_REQUEST,
  GET_MOST_LIKED_PODCASTS_SUCCESS,
  GET_MOST_VIEWED_PODCASTS_FAIL,
  GET_MOST_VIEWED_PODCASTS_REQUEST,
  GET_MOST_VIEWED_PODCASTS_SUCCESS,
  GET_PLAYLIST,
  GET_PODCASTS_FAIL,
  GET_PODCASTS_REQUEST,
  GET_PODCASTS_SUCCESS,
  GET_RECENT_PLAYS,
  INCREMENT_VIEWS_COUNT,
  LIKE_PODCAST,
  OPEN_AUDIO_PLAYER,
  PODCAST_DETAILS_FAIL,
  PODCAST_DETAILS_REQUEST,
  PODCAST_DETAILS_SUCCESS,
  RESET_ADDED,
  RESET_CURRENT_PLAY,
  SET_CURRENT_PODCAST,
  UNLIKE_PODCAST,
  UPDATE_CURRENT_PLAY,
  UPDATE_LIKES,
} from "../types";

export const podcastLikeReducer = (state = { success: false }, action) => {
  switch (action.type) {
    case LIKE_PODCAST:
      return { success: true };
    case UNLIKE_PODCAST:
      return { success: true };

    default:
      return state;
  }
};

export const podcastListReducer = (state = { podcasts: [] }, action) => {
  switch (action.type) {
    case GET_PODCASTS_REQUEST:
      return { ...state, loading: true };
    case GET_PODCASTS_SUCCESS:
      return {
        loading: false,
        podcasts: action.payload.data,
        pages: action.payload.meta.last_page,
        page: action.payload.meta.current_page,
      };
    case GET_PODCASTS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_LIKES:
      return {
        ...state,
        podcasts: state.podcasts.map((podcast) =>
          podcast._id === action.payload.id
            ? { ...podcast, likes: action.payload.likes }
            : podcast
        ),
        loading: false,
      };
    case CREATE_REVIEW_SUCCESS:
      return {
        ...state,
        podcasts: state.podcasts.map((podcast) =>
          podcast._id === action.payload.id
            ? { ...podcast, rating: action.payload.rating }
            : podcast
        ),
        loading: false,
      };
    default:
      return state;
  }
};

export const categoryPodcastsReducer = (state = { podcasts: [] }, action) => {
  switch (action.type) {
    case GET_CATEGORY_PODCASTS_REQUEST:
      return { ...state, loading: true };
    case GET_CATEGORY_PODCASTS_SUCCESS:
      return {
        loading: false,
        pages: action.payload.meta.last_page,
        page: action.payload.meta.current_page,
        podcasts: action.payload.data,
      };
    case GET_CATEGORY_PODCASTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const podcastLatestReducer = (
  state = { podcasts: [], loading: true },
  action
) => {
  switch (action.type) {
    case GET_LATEST_PODCASTS_REQUEST:
      return { loading: true };
    case GET_LATEST_PODCASTS_SUCCESS:
      return { loading: false, podcasts: action.payload };
    case GET_LATEST_PODCASTS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const podcastMostLikedReducer = (
  state = { podcasts: [], loading: true },
  action
) => {
  switch (action.type) {
    case GET_MOST_LIKED_PODCASTS_REQUEST:
      return { loading: true };
    case GET_MOST_LIKED_PODCASTS_SUCCESS:
      return { loading: false, podcasts: action.payload };
    case GET_MOST_LIKED_PODCASTS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const podcastMostViewedReducer = (
  state = { podcasts: [], loading: true },
  action
) => {
  switch (action.type) {
    case GET_MOST_VIEWED_PODCASTS_REQUEST:
      return { loading: true };
    case GET_MOST_VIEWED_PODCASTS_SUCCESS:
      return { loading: false, podcasts: action.payload };
    case GET_MOST_VIEWED_PODCASTS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
export const podcastDetailsReducer = (state = { podcast: null }, action) => {
  switch (action.type) {
    case PODCAST_DETAILS_REQUEST:
      return { ...state, loading: true };
    case PODCAST_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        podcast: action.payload,
      };
    case PODCAST_DETAILS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case UPDATE_LIKES:
      return {
        ...state,
        podcast: {
          ...state.podcast,
          likes: action.payload.likes,
          liked: action.payload.liked,
        },
        loading: false,
      };
    case CHECK_LIKE:
      return { ...state, alreadyLiked: !action.payload.success };
    default:
      return state;
  }
};
export const podcastViewsReducer = (state = {}, action) => {
  switch (action.type) {
    case INCREMENT_VIEWS_COUNT:
      return { success: action.payload };

    default:
      return state;
  }
};

export const playlistReducer = (
  state = { playlist: [], current: 0, added: false },
  action
) => {
  switch (action.type) {
    case ADD_TO_PLAYLIST:
      return {
        ...state,
        playlist:
          state.playlist.findIndex((x) => x.id === action.payload.id) !== -1
            ? [...state.playlist]
            : [...state.playlist, action.payload],
        added:
          state.playlist.findIndex((x) => x.id === action.payload.id) !== -1
            ? false
            : true,
      };
    case GET_PLAYLIST:
      return { ...state, playlist: [...state.playlist] };
    case RESET_ADDED:
      return { ...state, added: false };
    case UPDATE_CURRENT_PLAY:
      return { ...state, current: state.current + 1 };
    case RESET_CURRENT_PLAY:
      return { ...state, current: 0 };

    default:
      return state;
  }
};

export const recentReducer = (state = { recentPlays: [] }, action) => {
  switch (action.type) {
    case ADD_TO_RECENT_PLAYS:
      return {
        ...state,
        recentPlays:
          state.recentPlays.findIndex((x) => x.id === action.payload.id) !== -1
            ? [...state.recentPlays]
            : [...state.recentPlays, action.payload],
      };
    case GET_RECENT_PLAYS:
      return { recentPlays: [...state.recentPlays] };
    default:
      return state;
  }
};

export const likedPodcastsReducer = (state = { podcasts: [] }, action) => {
  switch (action.type) {
    case GET_LIKED_PODCASTS_REQUEST:
      return { ...state, loading: true };
    case GET_LIKED_PODCASTS_SUCCESS:
      return {
        loading: false,
        pages: action.payload.meta.last_page,
        page: action.payload.meta.current_page,
        podcasts: action.payload.data,
      };
    case GET_LIKED_PODCASTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const playerReducer = (
  state = { podcast: null, open: false },
  action
) => {
  switch (action.type) {
    case SET_CURRENT_PODCAST:
      return { open: true, podcast: action.payload };
    case OPEN_AUDIO_PLAYER:
      return { open: true };
    case CLOSE_AUDIO_PLAYER:
      return { open: false };

    default:
      return state;
  }
};
