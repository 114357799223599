import { FavoriteSharp, PlayArrow, Visibility } from "@mui/icons-material";
import { IconButton, Slide, Zoom } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Podcast = ({ podcast, handlePlay }) => {
  return (
    <Zoom in={true} timeout={3000}>
      <Link
        to={`/podcasts/${podcast?.id}`}
        className="group col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 w-[70%] sm:w-[80%] relative">
        <div className=" rounded relative group-hover:grayscale-[10%] transition duration-300 w-full cursor-pointer h-[15rem] md:h-[20rem]">
          {/* poster */}
          <img
            // src={`${URL}/${podcast.poster}`}
            src={`${podcast?.image}`}
            // src="/mSound_Templet.jpg"
            alt={`${podcast?.name}`}
            className="w-full rounded shadow h-full block"
          />

          <div className="absolute top-[50%] left-[50%] -translate-x-[50%]   grayscale-0 -translate-y-[50%]">
            <IconButton className="shadow-lg !p-2  !bg-black child:!text-yellow opacity-70   animate-ping-slow">
              <PlayArrow className="!text-[#F5C034]" />
            </IconButton>
          </div>
        </div>
        <div className="flex flex-col min-h-[5rem]  mt-2 pr-2">
          <div className="flex-1 h-full">
            <p className="text-[1rem]">{podcast?.name}</p>
            {/* podcast duration and upload date */}
            <div className="flex justify-between items-center mt-2">
              <p className="text-xs text-gray-500  font-bold ">
                {podcast?.duration.split(".")[0]} دقيقة
              </p>
              <div className="child:block">
                <span className="text-gray-500 text-xs">
                  {podcast?.created_at}
                </span>
              </div>
            </div>
          </div>
          <div className="pr-2 flex justify-between items-center ">
            {/* podcast icons */}
          </div>
        </div>
      </Link>
    </Zoom>
  );
};

export default Podcast;
