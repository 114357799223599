import { Pagination, ThemeProvider } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import theme from "../config/theme";
import { getLatestPodcasts } from "../redux/actions/podcastActions";
import { OPEN_AUTH_MODAL, SET_CURRENT_PODCAST } from "../redux/types";
import Loader from "./Loader";
import Podcast from "./Podcast";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import FeedIcon from "@mui/icons-material/Feed";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import CategoryIcon from "@mui/icons-material/Category";

const Content = () => {
  const [categoryId, setCategoryId] = useState("");
  const { categories } = useSelector((state) => state.categoriesList);

  const { user } = useSelector((state) => state.loginUser);

  const handlePlay = (podcast) => {
    if (user) return dispatch({ type: OPEN_AUTH_MODAL });
    dispatch({ type: SET_CURRENT_PODCAST, payload: podcast });
  };

  const { podcasts, loading, pages } = useSelector(
    (state) => state.podcastLatest
  );
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    dispatch(getLatestPodcasts(categoryId));
  }, [categoryId, dispatch, page]);

  const icons = {
    8: <ColorLensIcon />,
    9: <SportsSoccerIcon className="text-xl" />,
    10: <FeedIcon />,
    11: <FamilyRestroomIcon />,
  };

  return (
    <main>
      <div className="flex justify-center child:m-2 flex-wrap">
        <div
          onClick={() => setCategoryId("")}
          className={` ${
            categoryId === ""
              ? "btn-primary !bg-[#00678F] rounded shadow-lg  flex flex-col justify-center items-center p-3 md:p-4 md:px-8"
              : "btn-secondary rounded shadow-lg bg-black text-white flex flex-col justify-center items-center p-3 md:p-4 md:px-8"
          }`}
          // className="rounded shadow-lg bg-black text-white flex flex-col justify-center items-center p-3 md:p-6"
        >
          <div className="md:child:!text-6xl child:!text-3xl text-[#FFCC00]">
            <CategoryIcon />
          </div>
          <h4 className="font-bold mt-2">الجميع</h4>
        </div>
        {categories?.map((category) => (
          <div
            onClick={() => setCategoryId(category.id)}
            key={category.id}
            className={` ${
              categoryId === category.id
                ? "btn-primary !bg-[#00678F] rounded shadow-lg  flex flex-col justify-center items-center p-3 md:p-4 md:px-8"
                : "btn-secondary rounded shadow-lg bg-black text-white flex flex-col justify-center items-center p-3 md:p-4 md:px-8"
            }`}
            // className="rounded shadow-lg bg-[#E9E9E9]  flex flex-col justify-center items-center p-3 md:p-4 md:px-8"
          >
            <div className="md:child:!text-6xl child:!text-3xl text-[#FFCC00]">
              {icons[category.id]}
            </div>
            <h4 className="font-bold mt-2">{category.name}</h4>
          </div>
        ))}
      </div>
      <h2 className="text-center mt-10 text-3xl">احدث البودكاست</h2>

      {loading ? (
        <Loader />
      ) : (
        <div className="grid grid-cols-12 justify-items-center gap-y-9 my-6">
          {podcasts?.map((x) => (
            <Podcast podcast={x} key={x.id} handlePlay={() => handlePlay(x)} />
          ))}
        </div>
      )}

      <ThemeProvider theme={theme}>
        <div className="col-span-12 grid place-items-center my-6">
          {pages > 1 && (
            <Pagination
              color="primary"
              count={pages}
              page={page}
              onChange={handlePageChange}
              shape="rounded"
              variant="outlined"
            />
          )}
        </div>
      </ThemeProvider>
    </main>
  );
};

export default Content;
