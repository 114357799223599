import axios from "axios";
import URL, { portalId } from "../api";
import {
  AUTHENTICATE_USER_FAIL,
  AUTHENTICATE_USER_REQUEST,
  AUTHENTICATE_USER_SUCCESS,
  LOGOUT_USER,
  SUBSCRIBE_USER_FAIL,
  SUBSCRIBE_USER_REQUEST,
  SUBSCRIBE_USER_SUCCESS,
} from "../types";

export const subscribe = (user) => async (dispatch) => {
  try {
    dispatch({ type: SUBSCRIBE_USER_REQUEST });
    const { data: init } = await axios.get(
      "http://landing.mtn.sd/LandingPageBackend/init"
    );

    console.log(init);

    const { data: phone } = await axios.get("http://msoundsd.com/index.php");

    const { data } = await axios.post(
      URL + "/user/subscribe",
      { ...user, portal_id: portalId, id: init.transactionDetails.id, phone },
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );

    dispatch({ type: SUBSCRIBE_USER_SUCCESS, payload: data });
    dispatch({ type: AUTHENTICATE_USER_SUCCESS, payload: data });
    localStorage.setItem("user", JSON.stringify(data));

    if (data.MTNLandingUrl) window.location.href = data?.MTNLandingUrl;
  } catch (error) {
    dispatch({
      type: SUBSCRIBE_USER_FAIL,
      payload: error.response.data ? error.response.data.errors : error.message,
    });
    console.log(error);
  }
};

export const login = (user) => async (dispatch) => {
  try {
    dispatch({ type: AUTHENTICATE_USER_REQUEST });

    const { data } = await axios.post(
      URL + "/user/login",
      { ...user, portal_id: portalId },
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    dispatch({ type: AUTHENTICATE_USER_SUCCESS, payload: data });

    localStorage.setItem("user", JSON.stringify(data));
    console.log({ data });

    if (data.MTNLandingUrl) window.location.href = data?.MTNLandingUrl;
  } catch (error) {
    dispatch({
      type: AUTHENTICATE_USER_FAIL,
      payload: error.response.data ? error.response.data.errors : error.message,
    });
    console.log(error);
  }
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT_USER });
  localStorage.removeItem("user");
};
