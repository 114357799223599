import { Skeleton } from "@mui/material";
import React from "react";

const LoaderUnit = () => {
  return (
    <div className="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 w-[80%]">
      <Skeleton variant="rectangular" height={350} width={350} />
      <Skeleton variant="text" width={300} />
      <Skeleton variant="text" width={300} />
    </div>
  );
};

export default LoaderUnit;
