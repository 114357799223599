import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../assets/images/MTN_Logo.png";
import msounds from "../assets/images/MSounds.png";
import axios from "axios";
import URL from "../redux/api";
import { Button } from "@mui/material";
import { subscribe } from "../redux/actions/authActions";
import LoadingButton from "./LoadingButton";

const Hero = () => {
  const { user } = useSelector((state) => state.loginUser);
  // try {
  //   const { data } = await axios.get(
  //     "http://landing.mtn.sd/LandingPageBackend/init"
  //   );

  //   console.log(data);
  // } catch (error) {
  //   console.log(error);
  // }
  const handleSubscribe = async (msisdn) => {
    // makeRequest(
    //   "GET",
    //   "http://landing.mtn.sd/LandingPageBackend/init",
    //   function (err, data) {
    //     if (err) {
    //       return console.log({ err });
    //     }

    //     // data = JSON.parse(data);

    //     console.log(JSON.parse(data), "fjdkj");
    //     data = JSON.parse(data);
    //     window.location.href = `https://landing.mtn.sd/LandingPage/?id=${data.transactionDetails.id}`;
    //   }
    // );
    try {
      const { data } = await axios.get(
        "http://landing.mtn.sd/LandingPageBackend/init"
      );

      // const { data: validate } = await axios.post(
      //   "https://landing.mtn.sd/LandingPageBackend/partner/validate",
      //   {
      //     msisdn: msisdn,
      //     id: data.transactionDetails.id,
      //     keyword: "1",
      //     password: "service",
      //     shortcode: "5894",
      //   }
      // );

      // const { data } = await axios.get(`${URL}/user/subscribe`, {
      //   headers: {
      //     "Content-type": "application/json",
      //   },
      // });

      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.subscribeUser);

  useEffect(() => {
    // And we'd call it as such:
  }, []);
  return (
    <div className="bg-[#FFCC00] min-h-[50vh] flex flex-col items-center justify-center  lg:flex-row lg:justify-between pt-10 px-[2rem] md:px-[5rem] xl:px-[12rem]">
      <div className="flex-[.5] w-[60%] child:text-right">
        <div className="w-full  md:w-[80%]">
          <h2 className="lg:text-[30px] xl:text-[50px] md:text-[40px] font-bold text-[20px]">
            {" "}
            استمتع بالبودكاست الرائع من mtn
          </h2>
          <p className="lg:text-[25px]   ">
            {" "}
            إشترك الآن في منصة{" "}
            <img
              src={msounds}
              className="md:h-10 h-6 w-14 md:w-15 p-1 bg-white rounded-full  inline"
            />{" "}
            المقدم من <img src={logo} className="h-5 w-8 inline" /> وإستمع
            لمختلف المواضيع المتعلقة بالرياضة ، قضايا المرأة والفن.
          </p>
          {!user && (
            <LoadingButton
              loading={loading}
              // onClick={handleSubscribe}
              onClick={() => dispatch(subscribe())}>
              اشترك الآن
            </LoadingButton>
          )}
        </div>
      </div>
      <div className="flex-[.3] lg:flex-[.5]">
        <img src="/MAlamri2.png" className="block w-[80%]" alt="" />
      </div>
    </div>
  );
};

export default Hero;
