import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@mui/styles";

import CategoryPage from "./pages/CategoryPage";
import Explore from "./pages/Explore";

import Login from "./pages/Login";
import Subscribe from "./pages/Subscribe";
import { useEffect } from "react";
import PodcastPage from "./pages/PodcastPage";
import axios from "axios";

// Configure JSS
const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});
// const loader = document.querySelector("#loader");
// const hideLoader = () => loader.classList.add("hidden");
function App() {
  useEffect(() => {
    async function httpGet() {
      const data = await axios.get("http://msoundsd.com/index.php");
      console.log(data);
    }
    // console.log(httpGet(), "htis mtn response");
    // hideLoader();

    httpGet();
  }, []);
  return (
    <div className="App">
      <StylesProvider jss={jss}>
        <BrowserRouter>
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="/categories/:category" element={<CategoryPage />} />
            <Route path="/explore" element={<Explore />} />
            <Route path="/login" element={<Login />} />
            <Route path="/podcasts/:id" element={<PodcastPage />} />
            <Route path="/subscribe" element={<Subscribe />} />
          </Routes>
        </BrowserRouter>
      </StylesProvider>
    </div>
  );
}

export default App;
